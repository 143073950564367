import "./App.css";
import edge from "./assets/img/edge.png";
function App() {
  return (
    <div className="App">
      <header className="h-screen flex flex-col items-center justify-center bg-slate-100">
        <div className="w-[500px]">
          <h2 className="font-poppins text-2xl text-center text-gray-700">
            <span className="font-bold text-[#000564]">ChaiNET</span> is still
            under development.
          </h2>
          <h2 className="font-poppins font-normaltext-2xl text-justify p-3 text-gray-500">
            This website is currently under development and expected to be
            active on 15th January 2024. You can check our main project{" "}
            <a className="text-[#845E51] font-bold" href="https://chaidoro.net">
              chaidoro.net
            </a>
            . We are also working on artist portfolio pages. Currently we do not
            take any offers due to our busy schedule. Have a nice day.
          </h2>
          <h1 className="font-bold text-2xl text-center text-[#000564]">
            ૮꒰ ˶• ༝ •˶꒱ა ♡
          </h1>
        </div>
      </header>
      <div className="">
        <img
          className="absolute right-0 bottom-0 transform -scale-x-100 w-[300px]"
          src={edge}
          alt="edgeCorner"
        />
        <img
          className="absolute left-0 bottom-0 w-[300px]"
          src={edge}
          alt="edgeCorner"
        />
        <img
          className="absolute right-0 top-0 transform -scale-x-100 -scale-y-100 w-[300px]"
          src={edge}
          alt="edgeCorner"
        />
        <img
          className="absolute left-0 top-0 transform scale-x-100 -scale-y-100 w-[300px]"
          src={edge}
          alt="edgeCorner"
        />
      </div>
    </div>
  );
}

export default App;
